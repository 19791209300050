import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "./head";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import { Box, Grid, Button, Hidden } from "@material-ui/core";
import Img from "gatsby-image";

const TsuricationButton = () => {
  const data = useStaticQuery(graphql`
    query {
      tsuricationB1: file(relativePath: { eq: "tsuricationB1.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuricationB2: file(relativePath: { eq: "tsuricationB2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuricationB3: file(relativePath: { eq: "tsuricationB3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuricationB4: file(relativePath: { eq: "tsuricationB4.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box maxWidth="1100px" margin="auto" bgcolor="#ffffff">
        <Head />
        <Box width="100%" margin="auto">
          <Box
            margin="auto"
            textAlign="center"
            borderBottom={1}
            mt={5}
            mb={2}
            style={{ borderColor: "#333F69" }}
          >
            <WrapTypo
              Tsukubrdgothic
              fw="700"
              ls="2px"
              lh="180%"
              fs="20px"
              color="#333F69"
            >
              釣りレジャープラン
            </WrapTypo>
          </Box>

          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} sm={6} md={6} margin="auto">
              <Button style={{ width: "100%" }}>
                <Link
                  to="ここに、URLを載入して下さい"
                  style={{ width: "100%" }}
                >
                  <Box width="100%">
                    <Img
                      fluid={data.tsuricationB2.childImageSharp.fluid}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} margin="auto">
              <Button style={{ width: "100%" }}>
                <Link
                  to="ここに、URLを載入して下さい"
                  style={{ width: "100%" }}
                >
                  <Box width="100%">
                    <Img
                      fluid={data.tsuricationB1.childImageSharp.fluid}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} margin="auto">
              <Hidden xsDown>
                <Button style={{ width: "100%" }}>
                  <Link
                    to="https://www.ana.co.jp/ja/jp/domtour/theme/workation/shonai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "100%" }}
                  >
                    <Box width="100%">
                      <Img
                        fluid={data.tsuricationB3.childImageSharp.fluid}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Link>
                </Button>
              </Hidden>
              <Hidden smUp>
                <Button style={{ width: "100%" }}>
                  <Link
                    to="https://www.ana.co.jp/ja/jp/domtour/theme/workation/shonai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "100%" }}
                  >
                    <Box width="100%">
                      <Img
                        fluid={data.tsuricationB4.childImageSharp.fluid}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Link>
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TsuricationButton;
