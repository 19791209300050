import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "./head";
import { Box, Grid, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Img from "gatsby-image";

const WorkEnv = () => {
  const data = useStaticQuery(graphql`
    query {
      wifiicon: file(relativePath: { eq: "wifiicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coffeeicon: file(relativePath: { eq: "coffeeicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      viewicon: file(relativePath: { eq: "viewicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box maxWidth="1100px" margin="auto" bgcolor="#ffffff">
        <Head />
        <Box>
          <Box width="95%" margin="auto">
            <Box margin="auto" textAlign="center">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Box margin="auto" width="29%" mt={[6, 6, 10]} mb={[1, 1, 5]}>
                    <Img fluid={data.wifiicon.childImageSharp.fluid} />
                  </Box>
                  <Hidden smUp>
                    <Box mb={4} width="60%" maxWidth="300px" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        Wi-Fi完備。スムーズなネット環境でサクサクお仕事
                      </WrapTypo>
                    </Box>
                  </Hidden>
                  <Hidden xsDown>
                    <Box mb={5} width="70%" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        Wi-Fi完備。スムーズなネット環境でサクサクお仕事
                      </WrapTypo>
                    </Box>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box margin="auto" width="30%" mt={[3, 3, 10]} mb={[1, 1, 5]}>
                    <Img fluid={data.coffeeicon.childImageSharp.fluid} />
                  </Box>
                  <Hidden smUp>
                    <Box mb={4} width="60%" maxWidth="300px" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        仕事の合間に、
                        <br />
                        美味しいドリンクでほっと一息
                      </WrapTypo>
                    </Box>
                  </Hidden>
                  <Hidden xsDown>
                    <Box mb={5} width="70%" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        仕事の合間に、美味しいドリンクでほっと一息
                      </WrapTypo>
                    </Box>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box margin="auto" width="30%" mt={[3, 3, 10]} mb={[1, 1, 5]}>
                    <Img fluid={data.viewicon.childImageSharp.fluid} />
                  </Box>
                  <Hidden smUp>
                    <Box width="60%" maxWidth="300px" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        山、海、田んぼ…広大な自然を眺めて気分リフレッシュ
                      </WrapTypo>
                    </Box>
                  </Hidden>
                  <Hidden xsDown>
                    <Box mb={5} width="70%" margin="auto">
                      <WrapTypo
                        Notosans
                        ls="2px"
                        lh="200%"
                        fs="12px"
                        color="#333F69"
                      >
                        山、海、田んぼ…広大な自然を眺めて気分リフレッシュ
                      </WrapTypo>
                    </Box>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WorkEnv;
