import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";
import { Box, Grid, Button, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Img from "gatsby-image";
import "../css/slickOverride.css";
// import PrevArrowImg from "../../static/images/prevArrow.png";
// import NextArrowImg from "../../static/images/nextArrow.png";

const Carousel = props => {
  const { carouselElems } = props;
  const data = useStaticQuery(graphql`
    query {
      JTPImg: file(relativePath: { eq: "JTPImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ShonaiTravSiteImg: file(relativePath: { eq: "shonaiTravSiteImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SuidenImg: file(relativePath: { eq: "suidenImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  var settings = {
    arrows: true, //左右の矢印
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    dots: true
  };
  var xssettings = {
    arrows: true, //左右の矢印
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <Box width={["80%", "80%", "95%"]} margin="auto">
        <WrapTypo
          className="tac"
          fs="18px"
          sfs="12px"
          ls="10px"
          Tsukubrdgothic
          fw="700"
          mb="16px"
        >
          関連サイト
        </WrapTypo>
        <Hidden xsDown>
          <Slider {...settings} style={{ margin: "auto" }}>
            {carouselElems.map(elems => {
              return (
                <Box margin="auto" px={3}>
                  <Link
                    to={elems.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={elems.img} />
                  </Link>
                </Box>
              );
            })}
          </Slider>
        </Hidden>
        <Hidden smUp>
          <Slider {...xssettings}>
            {carouselElems.map(elems => {
              return (
                <Box margin="auto">
                  <Link
                    to={elems.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={elems.img} />
                  </Link>
                </Box>
              );
            })}
          </Slider>
        </Hidden>
      </Box>
    </>
  );
};

export default Carousel;
