import React, { useRef, useEffect } from "react";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "./WrapTypo";
import Head from "./head";
import Spaceimagebutton from "./spaceimagebutton";
import nl2br from "../components/nl2br";

const Placearea = (props) => {
  const { title, content, pics, place, logo, isOptionLink = false } = props;

  const data = useStaticQuery(graphql`
    query {
      placeicon: file(relativePath: { eq: "placeicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Box maxWidth="1100px" margin="auto" bgcolor="#ffffff">
        <Head />
        <Box>
          <Box width="95%" margin="auto">
            <Box margin="auto">
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box mb={2} mt={[4, 4, 10]}>
                    <WrapTypo
                      Tsukubrdgothic
                      fw="700"
                      ls="2px"
                      lh="180%"
                      fs="24px"
                      color="#333F69"
                    >
                      {nl2br(title)}
                    </WrapTypo>
                  </Box>
                  <Box width={["100%", "100%", "90%"]} mt={[2, 2, 4]}>
                    <WrapTypo
                      Notosans
                      ls="2px"
                      lh="250%"
                      fs="13px"
                      color="#333F69"
                    >
                      {content}
                    </WrapTypo>
                  </Box>

                  {props.isOptionLink?.map((c) => {
                    return (
                      <Box mt={4}>
                        <Link
                          to={c.link}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WrapTypo Tsukubrdgothic>{c.title}</WrapTypo>
                        </Link>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box width="100%">
                    <Spaceimagebutton
                      picsarray={pics}
                      title={place}
                      logo={logo}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Placearea;
