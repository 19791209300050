import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import WrapTypo from "../components/WrapTypo";
import BgiWave from "../../static/images/footer_bgimg.svg";
import SPWave from "../../static/images/footer_SPimg.svg";

const styles = {
  paperContainer: {
    backgroundSize: "cover",
    width: "100%",
    backgroundImage: `url(${BgiWave})`,
    margin: 0,
    backgroundRepeat: "no-repeat",
    alignContent: "flex-end",
  },
};
const spstyles = {
  paperContainer: {
    backgroundSize: "cover",
    width: "100%",
    backgroundImage: `url(${SPWave})`,
    margin: 0,
    backgroundRepeat: "no-repeat",
    alignContent: "flex-end",
  },
};

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      logo_mono: file(relativePath: { eq: "logo_mono.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_work: file(relativePath: { eq: "footer_work.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_tsuri: file(relativePath: { eq: "footer_fishing.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_tomari: file(relativePath: { eq: "footer_stay.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nav_map: file(relativePath: { eq: "footer_map.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Box
        margin="auto"
        // height="500px"
        style={{ width: "100%", alignItems: "flex-end" }}
      >
        <Box mt={3}>
          <Hidden smDown>
            <Box
              margin="auto"
              pt={[0, 0, 0, 10]}
              pb={10}
              width="100%"
              style={styles.paperContainer}
            >
              <Box width="95%" margin="auto">
                <Grid container>
                  <Grid item xs={12} sm={3} md={2}>
                    <Box width="100%" mt={11}>
                      <Img
                        fluid={data.logo_mono.childImageSharp.fluid}
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={5} md={4} lg={3}>
                    <Box pt={5}>
                      <Box pb={1}>
                        <WrapTypo
                          color="#FFFFFF"
                          fs="16px"
                          sfs="14px"
                          ls="2px"
                          Tsukubrdgothic
                          fw="700"
                        >
                          問い合わせ先
                        </WrapTypo>
                      </Box>
                      <WrapTypo
                        color="#FFFFFF"
                        fs="15px"
                        sfs="14px"
                        Tsukubrdgothic
                        fw="700"
                      >
                        庄内浜釣りケーション実行委員会
                      </WrapTypo>
                      <WrapTypo
                        color="#FFFFFF"
                        fs="15px"
                        sfs="14px"
                        Tsukubrdgothic
                        fw="700"
                      >
                        （info.tsurication@gmail.com）
                      </WrapTypo>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box margin="auto" style={spstyles.paperContainer}>
              <Box pt={[15, 30, 30]} pb={5}>
                <Box width="100%" margin="auto" justifyContent="flex-end">
                  <Img
                    fluid={data.logo_mono.childImageSharp.fluid}
                    style={{ width: "40%", margin: "auto" }}
                  />
                </Box>
                <Box margin="auto" textAlign="center" width="100%">
                  <Button style={{ width: "40%", margin: "auto" }}>
                    <Link to="/#work" style={{ width: "100%" }}>
                      <Box width="100%" margin="auto">
                        <Img
                          fluid={data.nav_work.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                    </Link>
                  </Button>
                </Box>
                <Box margin="auto" textAlign="center" width="100%">
                  <Button style={{ width: "40%", margin: "auto" }}>
                    <Link to="/#tsuri" style={{ width: "100%" }}>
                      <Box width="100%">
                        <Img
                          fluid={data.nav_tsuri.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                    </Link>
                  </Button>
                </Box>
                <Box margin="auto" textAlign="center" width="100%">
                  <Button style={{ width: "40%", margin: "auto" }}>
                    <Link to="/#hotel" s style={{ width: "100%" }}>
                      <Box width="100%">
                        <Img
                          fluid={data.nav_tomari.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                    </Link>
                  </Button>
                </Box>
                <Box margin="auto" textAlign="center" width="100%">
                  <Button style={{ width: "40%", margin: "auto" }}>
                    <Link to="/#map" style={{ width: "100%" }}>
                      <Box width="100%">
                        <Img
                          fluid={data.nav_map.childImageSharp.fluid}
                          style={{ width: "100%" }}
                        />
                      </Box>
                    </Link>
                  </Button>
                </Box>
              </Box>
              <Box pb={5} margin="auto" textAlign="center" width="70%">
                <Box pb={1}>
                  <WrapTypo
                    color="#FFFFFF"
                    fs="16px"
                    sfs="14px"
                    ls="2px"
                    Tsukubrdgothic
                    fw="700"
                  >
                    問い合わせ先
                  </WrapTypo>
                </Box>
                <WrapTypo
                  color="#FFFFFF"
                  fs="15px"
                  sfs="14px"
                  Tsukubrdgothic
                  fw="700"
                >
                  庄内浜釣りケーション実行委員会
                </WrapTypo>
                <WrapTypo
                  color="#FFFFFF"
                  fs="15px"
                  sfs="14px"
                  Tsukubrdgothic
                  fw="700"
                >
                  （info.tsurication@gmail.com）
                </WrapTypo>
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
