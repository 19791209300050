import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import WrapTypo from "../components/WrapTypo";
import WorcationBaseimg from "../../static/images/WorcationBaseimg.svg";

function WorcationBase() {
  return (
    <>
      <Hidden smDown>
        <Box
          width="100%"
          margin="auto"
          mt={10}
          px={20}
          py={25}
          data-aos="fade-in"
          style={{
            backgroundImage: `url(${WorcationBaseimg})`,
            backgroundRepeat: "no-repeat",

            backgroundSize: "100%"
          }}
        >
          <Box width="100%" textAlign="center" mb={[0, 0, 3, 11]}>
            <WrapTypo
              Tsukubrdgothic
              ls="15px"
              fs="20px"
              color="#333F69"
              fw="700"
              lh="250%"
            >
              庄内浜で「ワーク」と「釣り」を
              <br />
              楽しみながらワーケーションの
              <br />
              拠点としてみませんか。
            </WrapTypo>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box
          maxWidth="1500px"
          width="100%"
          margin="auto"
          mt={[0, 10]}
          mb={[5]}
          px={[3, 10, 10, 20]}
          py={[5, 15, 15, 25]}
          data-aos="fade-in"
          style={{
            backgroundImage: `url(${WorcationBaseimg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "100%"
          }}
        >
          <Box width="100%" textAlign="center" mb={[4, 4, 3, 11]}>
            <WrapTypo
              Tsukubrdgothic
              ls="6px"
              fs="16px"
              color="#333F69"
              fw="600"
              lh="250%"
            >
              庄内浜で「ワーク」と「釣り」 を楽しみながら、
              ワーケーションの拠点としてみませんか。
            </WrapTypo>
          </Box>
        </Box>
      </Hidden>
    </>
  );
}

export default WorcationBase;
