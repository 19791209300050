import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import BoxTypo from "../components/BoxTypo";
import mizutama from "../../static/images/mizutama.png";
// import { compilePseudoSelector } from "css-select/lib/pseudo-selectors";

function News(props) {
  const { data, isNewsPage = false } = props;
  const [pageNation, setPagenation] = useState(0);
  return (
    <>
      <Box width="100%" mt={[5, 10]} pb={10}>
        <hr
          width="70px"
          className="tac ma mb64"
          style={{ transform: "rotate(90deg)" }}
        />
        <WrapTypo
          className="tac"
          fs="24px"
          ls="10px"
          Tsukubrdgothic
          fw="700"
          mb="64px"
        >
          {isNewsPage ? "その他のNEWS" : "NEWS"}
        </WrapTypo>
        {data?.slice(pageNation * 5, pageNation * 5 + 5).map((c) => {
          return (
            <Content
              paddingTop="0"
              borderBottom="solid 1px #707070"
              day={c.node.date}
              pageurl={c.node.newsId}
              title={c.node.title}
            />
          );
        })}
        <Box className="df w100 jcsa" textAlign="center" mt={5}>
          <BoxTypo
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            onClick={() => {
              setPagenation((c) => (c === 0 ? c : c - 1));
            }}
          >
            ◀︎
          </BoxTypo>
          <BoxTypo
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            onClick={() => {
              setPagenation((c) => data - 1);
            }}
          >
            {pageNation + 1}/
            {Math.floor(data.length / 5) + (data.length % 5 >= 1 ? 1 : 0)}
          </BoxTypo>
          <BoxTypo
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            onClick={() => {
              setPagenation((c) =>
                Math.floor(data.length / 5) + (data.length % 5 >= 1 ? 1 : 0) >=
                c + 2
                  ? c + 1
                  : c
              );
            }}
          >
            ▶︎
          </BoxTypo>
        </Box>
      </Box>
    </>
  );
}

function Content(Props) {
  return (
    <Box width="90%" maxWidth="700px" m={"auto"} mt={5}>
      <Grid container justify="center">
        <Grid item xs={12} sm={3} md={6} lg={6}>
          <Box textAlign="left">
            <WrapTypo fs="16px" sfs="12px" Tsukubrdgothic fw="700">
              {Props.day}
            </WrapTypo>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} md={6} lg={6}>
          <Box textAlign="left">
            <a
              href={"/news/" + Props.pageurl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <WrapTypo fs="16px" sfs="14px" Tsukubrdgothic fw="700">
                {Props.title}
              </WrapTypo>
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default News;
