import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";
import { Box, Grid, Button, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Img from "gatsby-image";
import "../css/slickOverride.css";
import FishBgLeft from "../../static/images/fish_bg_left.png";
import FishBgRight from "../../static/images/fish_bg_right.png";
// import PrevArrowImg from "../../static/images/prevArrow.png";
// import NextArrowImg from "../../static/images/nextArrow.png";

const Fish = (props) => {
  const data = useStaticQuery(graphql`
    query {
      spring: file(relativePath: { eq: "spring.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      summer: file(relativePath: { eq: "summer.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      autom: file(relativePath: { eq: "autom.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      winter: file(relativePath: { eq: "winter.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fish_button: file(relativePath: { eq: "fish_button.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  var settings = {
    arrows: true, //左右の矢印
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    dots: true,
  };
  var xssettings = {
    arrows: true, //左右の矢印
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const carouselElems = [
    {
      link: "",
      img: data.spring.childImageSharp.fluid,
    },
    {
      link: "",
      img: data.summer.childImageSharp.fluid,
    },
    {
      link: "",
      img: data.autom.childImageSharp.fluid,
    },
    {
      link: "",
      img: data.winter.childImageSharp.fluid,
    },
  ];

  return (
    <>
      <Box width={["80%", "80%", "100%"]} margin="auto">
        <WrapTypo
          className="tac"
          fs="24px"
          sfs="18px"
          ls="10px"
          Tsukubrdgothic
          fw="700"
          mb="16px"
        >
          〜旬のお魚情報〜
        </WrapTypo>
        <Hidden xsDown>
          <Box display="flex">
            <Box
              width="20%"
              style={{
                backgroundImage: `url(${FishBgLeft})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            ></Box>
            <Box width="60%" className="ma">
              <Slider {...settings} style={{ margin: "auto" }}>
                {carouselElems.map((elems) => {
                  return (
                    <Box margin="auto" px={3}>
                      <Img fluid={elems.img} />
                    </Box>
                  );
                })}
              </Slider>
            </Box>
            <Box
              width="20%"
              style={{
                backgroundImage: `url(${FishBgRight})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "100%",
              }}
            ></Box>
          </Box>
        </Hidden>
        <Hidden smUp>
          <Slider {...xssettings}>
            {carouselElems.map((elems) => {
              return (
                <Box margin="auto">
                  <Img fluid={elems.img} />
                </Box>
              );
            })}
          </Slider>
        </Hidden>
        <Box mt={[5, 5, 5]} width={["60%", "20%"]} m={"auto"}>
          <Link
            to="http://joho.shonai-hama.net/?page_id=147"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fluid={data.fish_button.childImageSharp.fluid} />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Fish;
