import React from "react";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";
import Coworking from "../components/Placearea";
import Navigation from "../components/navigation";
import Img from "gatsby-image";
import "../css/base.css";
import WrokEnv from "../components/workEnv";
import TsuriEvent from "../components/tsuricationButton";
import WrapTypo from "../components/WrapTypo";
import ConceptBgImg from "../../static/images/concept_bg_img.png";
import concept_bg_img_sp from "../../static/images/concept_bg_img_sp.png";
import BoxTypo from "../components/BoxTypo";

const Concept = () => {
  const data = useStaticQuery(graphql`
    query {
      conceptImg: file(relativePath: { eq: "concept_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden smDown>
        <Box
          maxWidth="1100px"
          width="100%"
          margin="auto"
          mt={"-5%"}
          pb={40}
          style={{
            backgroundImage: `url(${ConceptBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "100%",
          }}
        >
          {/* <BoxTypo
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            width={["80%", "60%"]}
            pb={10}
          >
            【お知らせ】
            <br />
            9月19日、25日に予定していたモニターツアーは、
            <br />
            新型コロナウィルス感染拡大状況に鑑み、催行を中止しました。
          </BoxTypo> */}
          <WrapTypo
            mt={"32px"}
            Tsukubrdgothic
            ls="12px"
            fs="36px"
            color="#333F69"
            fw="700"
            lh="230%"
          >
            ワーケーションしながら
            <br />
            気軽に釣りを楽しめる、
            <br />
            庄内浜釣りケーション。
          </WrapTypo>
          <Box width="40%">
            <WrapTypo
              Tsukubrdgothic
              ls="2px"
              fs="18px"
              mt="16px"
              color="#333F69"
              fw="500"
              lh="230%"
              style={{ textAlign: "justify" }}
            >
              庄内地域は日本海に面し、広大な平野が広がる自然豊かな地域です。都会を離れた庄内での「ワークタイム」の合間に、庄内浜に出かけて「釣り」でリフレッシュ。目の前に広がる日本海に心解かれる瞬間は、仕事への活力をチャージする時間となるでしょう。庄内浜でワーケーションをしながら、釣りを楽しむ「庄内浜釣りケーション」にでかけませんか。
            </WrapTypo>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box
          maxWidth="1100px"
          width="100%"
          margin="auto"
          mt={5}
          style={{
            backgroundImage: `url(${concept_bg_img_sp})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          <Box className="w90 ma">
            {/* <BoxTypo
              fs={["12px"]}
              ls={["2px"]}
              lh={["200%"]}
              mt={"-10%"}
              width={["100%", "60%"]}
              pb={10}
              textAlign="justify"
            >
              【お知らせ】
              <br />
              9月19日、25日に予定していたモニターツアーは、新型コロナウィルス感染拡大状況に鑑み、催行を中止しました。
            </BoxTypo> */}
            <WrapTypo
              Tsukubrdgothic
              ls="4px"
              fs="22px"
              color="#333F69"
              fw="700"
              lh="250%"
            >
              ワーケーションしながら
              <br />
              気軽に釣りを楽しめる、
              <br />
              庄内浜釣りケーション。
            </WrapTypo>

            <WrapTypo
              style={{ textAlign: "justify" }}
              Tsukubrdgothic
              mt="32px"
              ls="4px"
              fs="14px"
              color="#333F69"
              fw="500"
              lh="220%"
            >
              庄内地域は日本海に面し、広大な平野が広がる自然豊かな地域です。都会を離れた庄内での「ワークタイム」の合間に、庄内浜に出かけて「釣り」でリフレッシュ。目の前に広がる日本海に心解かれる瞬間は、仕事への活力をチャージする時間となるでしょう。庄内浜でワーケーションをしながら、釣りを楽しむ「庄内浜釣りケーション」にでかけませんか。
            </WrapTypo>
          </Box>
          <Img fluid={data.conceptImg.childImageSharp.fluid} className="mt32" />
        </Box>
      </Hidden>
    </>
  );
};

export default Concept;
