import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import ButtonBase from "@material-ui/core/ButtonBase";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
// import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "../components/WrapTypo";
import Head from "../components/head";
import Button from "@material-ui/core/Button";
import nl2br from "../components/nl2br";
import { Buffer } from "buffer";

// import Fotter from "../components/cg_footer";

const Spaceimagebutton = props => {
  const { picsarray, title, logo } = props;

  const data = useStaticQuery(graphql`
    query {
      coworkingicon: file(relativePath: { eq: "coworkingicon.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Group126: file(relativePath: { eq: "Group126.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden smDown>
        <Box mt={5}>
          <Grid container>
            <Grid
              item
              xs={1}
              md={1}
              margin="auto"
              alignItems="center"
              justify="center"
            >
              <Box width="20px" margin="auto" mt={1}>
                <Img fluid={logo} />
              </Box>
            </Grid>
            <Grid
              item
              xs={11}
              md={11}
              margin="auto"
              alignItems="center"
              justify="center"
            >
              <WrapTypo
                Tsukubrdgothic
                fw="700"
                ls="2px"
                lh="180%"
                fs="20px"
                color="#333F69"
              >
                {title}
              </WrapTypo>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box mt={5} maxWidth="280px" margin="auto">
          <Grid container>
            <Grid
              item
              xs={1}
              md={1}
              margin="auto"
              alignItems="center"
              justify="center"
            >
              <Box width="20px" margin="auto" mt={1}>
                <Img fluid={logo} />
              </Box>
            </Grid>
            <Grid
              item
              xs={11}
              md={11}
              margin="auto"
              alignItems="center"
              justify="center"
            >
              <WrapTypo
                Tsukubrdgothic
                fw="700"
                ls="2px"
                lh="180%"
                fs="20px"
                color="#333F69"
              >
                {title}
              </WrapTypo>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Box textAlign="center" mb={5}>
        <Box margin="auto">
          <Grid container style={{ width: "100%" }}>
            {picsarray.map(pic => {
              return (
                <Grid item xs={6} sm={4} md={4} margin="auto">
                  {pic.isDisable ? (
                    <Box width="100%" py={1} px={1}>
                      <Img fluid={pic.img} style={{ width: "100%" }} />
                    </Box>
                  ) : (
                    <Button style={{ width: "100%" }}>
                      <Link
                        to={pic.link}
                        style={{ width: "100%" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Box width="100%">
                          <Img fluid={pic.img} style={{ width: "100%" }} />
                        </Box>
                      </Link>
                    </Button>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Spaceimagebutton;
